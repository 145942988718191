import React from 'react';

import Modal from '../../components/Modal';
import Video from '../../components/Video';

const ModalContent = () => (
	<Modal className="Modal" closeTo="/#what-does-this-look-like">
		<Video
			className="Modal__video"
			videoURL="https://player.vimeo.com/video/396986288/?autoplay=1&transparent=0"
			videoTitle=""
		/>
	</Modal>
);

export default ModalContent;
