import React from 'react';
import PropTypes from 'prop-types';

class Video extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		videoURL: PropTypes.string.isRequired,
		videoTitle: PropTypes.string.isRequired,
	};

	render() {
		const { className, videoURL, videoTitle } = this.props;

		return (
			<div className={className}>
				<iframe
					src={videoURL}
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
					allowFullScreen
					title={videoTitle}
				/>
			</div>
		);
	}
}

export default Video;
