import React from 'react';
import PropTypes from 'prop-types';
import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import './Modal.scss';

class Modal extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		closeTo: PropTypes.string.isRequired,
		modalTitle: PropTypes.string,
	};

	render() {
		const { children, closeTo } = this.props;

		return (
			<>
				<ModalRoutingContext>
					{({ modal }) => (
						<Link className="Modal__close" to={`${closeTo}`}>
							<i className="fal fa-times fa-2x"></i>
						</Link>
					)}
				</ModalRoutingContext>
				{children}
			</>
		);
	}
}

export default Modal;
